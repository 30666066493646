<template>
  <v-navigation-drawer
    :mini-variant.sync="mini"
    fixed
    :permanent="breakpoint('xs')"
    width="280"
    :expand-on-hover="!fixed"
    bottom
    :class="[breakpoint('sm') ? 'pt-16' : 'pt-14']"
  >
    <div
      class="d-flex flex-column justify-start align-stretch fill-height"
    >
      <v-list 
        class="py-0"
      >
        <v-list-item
          v-for="(page, id) in list"
          :key="'page'+id"
          :to="{ name: page.route, params: { brand, campaign: $route.params.campaign, groups: $route.params.groups, period: $route.params.period }}"
          exact
          active-class="active"
          class="nav-link font-weight-medium"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>{{ icons[id] }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t(page.title) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-spacer />

      <v-divider />

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!mobile"
            icon
            v-bind="attrs"
            v-on="on"
            class="grey--text ml-2 my-2"
            @click="toggleNav"
          >
            <v-icon small>
              {{ fixed ? icons.contract : icons.expand }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ fixed ? 'Desafixar menu' : 'Fixar menu' }}</span>
      </v-tooltip>
    </div>
  </v-navigation-drawer>
</template>

<style scoped>

  .nav-link {
    color: var(--mobees-black);
    opacity: .8;
  }
  .nav-link.active {
    color: var(--city-blue-A400);
    opacity: 1;
  }
  .nav-link.faded {
    opacity: .6;
  }
  .small {
    font-size: .8em !important;
  }

</style>

<script>
  import { mdiAccount, mdiAccountGroup, mdiClock, mdiTimelapse, mdiCurrencyUsd, mdiHome, mdiImage, mdiMap, mdiPin, mdiPinOff, mdiDownload, mdiText, mdiCamera } from '@mdi/js';
  import services, { icons } from '@/services';
  import _ from 'lodash';

  export default {
    name: 'DasboardNav',

    props: {
      pages: {
        type: Object,
        default: () => {
          return {}
        }
      },
      scope: {
        type: Number,
        default: null
      },
      children: {
        type: Array,
        default: () => {
          return []
        }
      },
      brand: {
        type: [Number, String],
        default: null
      },
      campaigns: {
        type: [Array, Object],
        default: () => {
          return {}
        }
      },
      loading: {
        type: Boolean,
        default: false
      },
      admin: {
        type: Boolean,
        default: true
      },
      auditor: {
        type: Boolean,
        default: false,
      },
      user: {
        type: Object,
        default: () => {}
      },
      size: {
        type: Object,
        default: () => {
          return { 
            width: 0,
            height: 0
          }
        }
      },
      mobile: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      icons: {
        overview: mdiHome,
        live: mdiTimelapse,
        map: mdiMap,
        demographics: mdiAccountGroup,
        audience: mdiAccount,
        impressions: mdiImage,
        spent: mdiCurrencyUsd,
        cpm: icons.cpm,
        hours: mdiClock,
        expand: mdiPin, 
        contract: mdiPinOff,
        report: mdiDownload,
        gallery: mdiCamera
      },
      btn: {
        report: {
          roles: [1,6,7],
          loading: false
        }
      },
      mini: true,
      fixed: false,
      plan: false,
    }),

    computed: {
      childrenList () {
        let children = null;
        if (_.size(this.campaigns)>0&&this.scope!=null&&_.has(this.campaigns, this.scope)) {
          const campaign = this.campaigns[this.scope];
          children = campaign.children.length > 0 ? {} : null;
          if (children!=null){
            _.each(this.campaigns[this.scope].children, c => {
              children[c] = this.campaigns[c];
            });
          }
        }
        return children;
      },
      list () {
        const roles = this.user.roles;
        return _.pickBy(this.pages, page => {
          return !_.has(page, 'roles') ||  _.size(_.intersection(page.roles, roles))>0;
        });
      },
      showReport () {
        const role = _.last(this.user.roles);
        return _.indexOf(this.btn.report.roles, role)>=0;
      }
    },

    watch: {
      size: {
        immediate: true,
        deep: true,
        handler (size) {
          if (this.breakpoint('md')) {
            console.log('fixed');
            this.fixed = true;
            this.mini = false;
          }else{
            this.fixed = false;
            this.mini = true;
          }
          this.$emit('nav-fixed', this.fixed);
        }
      }
    },

    methods: {
      ...services,
      
      toggleNav (fix) {
        this.fixed = fix===true || !this.fixed;
        this.$emit('nav-fixed', this.fixed);
      },
    },

    mounted () {
      // this.toggleNav(this.breakpoint('md'));
    },
  }
</script>
